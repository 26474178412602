import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../Actions';
import * as pageTypes from '../pageTypes';

import Survey from './Survey';
import Quiz from './Quiz';
import GeojsonMap from './GeojsonMap';

function QuizWithRecord(props) {
  switch (props.page) {
    case pageTypes.PAGE_QUESTION:
      return <Quiz />;
    case pageTypes.PAGE_SURVEY:
      return <Survey lang="es" />;
    case pageTypes.PAGE_MAP:
      return <GeojsonMap/>
    default:
      return `Error! Unknown page type ${props.page}`;
  }
}

QuizWithRecord.defaultProps = { page: pageTypes.PAGE_QUESTION, position: 0 };

QuizWithRecord.propTypes = { page: PropTypes.string, position: PropTypes.number };

const ConnectedQuizWithRecord = connect(
  state => ({ page: state.main.page, position: state.main.position }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(QuizWithRecord);

export default ConnectedQuizWithRecord;
