import Cities from './cities';
import CitiesData from '../cities.json';
import rules from '../quiz-rules.json';

export default class Results {

  constructor(len) {
    this.data = new Array(len);
    this.maxRecorded = -1;
    this.length = len;
  }

  actualPos() {
    return this.data.filter(a => a !== undefined).length;
  }

  nextPos() {
    return this.actualPos();
  }

  is(pos, value) {
    return this.data[pos] === value;
  }

  set(value, pos) {
    this.data[pos] = value;
  }

  get(pos) {
    return this.data[pos];
  }

  setRecorded(pos) {
    this.maxRecorded = Math.max(this.maxRecorded, pos);
  }

  recorded(pos) {
    return pos <= this.maxRecorded;
  }

  calculateCities(quiz) {
    const cities = [];
    this.data.forEach((res, i) => {
      if (!res) return;
      const prons = quiz[i].prons;
      prons[res] && prons[res].w && prons[res].w.forEach((city) => {
        if (cities[city]) {
          cities[city] += 1;
        } else {
          cities[city] = 1;
        }
      });
    });
    Object.keys(cities).forEach(cityId => {
      const parent = CitiesData[cityId].parent;
      if (parent) {
        cities[cityId] += cities[parent] || 0;
      }
    })
    this.data.forEach(res => {
      if (rules.excludes[res]) {
        rules.excludes[res].forEach(e => {
          //console.log(`Change for ${res}: ${e} from ${cities[e]} to 0;`);
          cities[e] = 0
        })
      }
    })
    return new Cities(Object.entries(cities).sort((a, b) => b[1] - a[1]));
  }

  prepareAnswers(quiz) {
    const answers = [];
    this.data.forEach((res, i) => {
      if (!res) return;
      const qw = quiz[i];
      answers.push({
        question_text: qw.name,
        answer_text: qw.prons[res] ? qw.prons[res].t : res,
      });
    });
    return answers;
  }

}
