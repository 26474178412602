const apiServer = 'https://prdhispa.unil.ch';
module.exports.apiServer = apiServer;
module.exports.defaultZoom = 3;

module.exports.apiRequestRecord = `${apiServer}/api/v1/record/`;
module.exports.apiPredictionQuiz = `${apiServer}/api/v1/prediction-quiz/`;
module.exports.apiSubmitSurvey = `${apiServer}/api/v1/submit_survey/`;
module.exports.apiPredictionQuizAnswer = `${apiServer}/api/v1/survey/answers/`;

module.exports.locationsId = { born: 4, liveIn: 6, grown: 5 };

module.exports.doNotSendAudio = true; // debug only. Prevents sound sample uploading
module.exports.MaxRecordTime = 5000; // Delay to autostop sample recording
module.exports.Center = { lat: 53.219383, lng: 6.566502 };

//module.exports.MapboxKey = 'pk.eyJ1IjoiaWdvcmRzIiwiYSI6ImNqd3g0b2JwNDBkZDE0Y3BtNXlkN215a28ifQ.1bmLiTSRR8Vx8POVDD4IpQ'
module.exports.MapboxKey = 'pk.eyJ1IjoiZGlhbGVjdG9zIiwiYSI6ImNqd21scGZkZTF6MTI0YW8ydnRob3dpYjcifQ.tfQnpGDERj2Olx3PtTJQSA'
module.exports.GoogleKey = 'AIzaSyBxim92jeIw--Jx4tPRSPap00fdQN0Bb-U';
//module.exports.GoogleKey = 'AIzaSyCMgOw-ReldbJnjjWiVcCkMuBjuxmsrbkU';

module.exports.linkPrefix = '';
module.exports.WinnerColors = ['#0f0', '#1d8', '#8ccc12'];

module.exports.singleMarker = true; // If show only single location marker
module.exports.hideMarkersOnResults = true;
module.exports.dryRun = false;
module.exports.useGoogle = false;

module.exports.fakeLocation = { name: "Deepest tundra", lat: 80.3054131181764, lng: 94.70129798609707, title: "Nací en" };

module.exports.apiHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
