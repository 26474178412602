import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Menu from './Menu';
import Results from '../models/results';
import I18n from '../i18n.json';
import quizData from '../quiz.json';
import * as actions from '../Actions';
import { shuffle } from '../functions'
import ProgressBar from './ProgressBar';
import  Title from './Title';

const emptyState = {
  playing: 0,
};


class SubmittableEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: Number(props.value) ? '' : (props.value || ''),
    }
  }

  render() {
    const btn = (this.state.value) ? (<button onClick={() => this.props.onSelect(this.state.value)} className='quiz--submit-text-button'>
          <i className='fa fa-check quiz--submit-text-icon'/>{I18n.submitEdit}
        </button>) : "";
    return (
      <div className='form-group form-inline quiz-input-wrapper'>
        <input
          className='form-control quiz-input'
          type='text'
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.props.onSelect(this.state.value);
            }
          }}
        />
        {btn}
      </div>
    );
  }

}

function Variant(props) {
  const checked = props.checked ? 'checked' : '';
  const action = (props.textField) ? () => {} : props.onSelect;
  const ext = (props.textField) ? <SubmittableEdit value={props.id} onSelect={props.onSelect}/> : '';
  return (
    <li
      className="list-item"
      key={props.id}
    >
      <div className={`hover clearfix ${checked} ${props.guided ? 'guided' : ''}`}>
        <label
          htmlFor="nothing"
          role="button"
          tabIndex={-1}
          id={props.id}
          onClick={() => action()}
        >{props.text}</label>
        {ext}
      </div>
    </li>);
}

Variant.defaultProps = { checked: false, style: undefined, textField: false, guided: false };

Variant.propTypes = {
  style: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  textField: PropTypes.bool,
  checked: PropTypes.bool,
  guided: PropTypes.bool,
};

function i18n(a) {
  if (!I18n[a]) console.warn(`No i18 4 "${a}"!`);
  return I18n[a];
}


class Quiz extends Component {

  constructor(props) {
    super(props);
    this.quiz = [];
    Object.values(quizData).forEach((v) => {
      this.quiz.push(v);
    });
    this.state = emptyState;
  }

  render() {
    const pos = this.props.position;
    const block = this.props.quiz[pos];
    const variants = shuffle(Object.entries(block.prons))
    .sort((e1, e2) => e2[1].textField ? -1 : 1)
    .map(v => (<Variant
      key={v[0]}
      id={v[0]}
      text={v[1].t}
      guided={v[1].w && v[1].w.indexOf(this.props.guideMeTo) !== -1}
      textField={v[1].textField}
      checked={this.props.results.is(this.props.position, v[0])}
      onSelect={(val) => this.props.actions.vote(val || v[0])}
    />));
    const nextControl = (pos < this.props.results.actualPos()) ? (<button
      className="btn btn-info pull-left"
      onClick={() => this.props.actions.goLast()}
    >{i18n('next')}</button>) : undefined;

    const controls = (pos) ? (<div>
      <button
        className="btn btn-info prev pull-left"
        onClick={() => this.props.actions.back()}
      >{i18n('quiz-prev')}</button>
      {nextControl}
    </div>) : '';
    const image = (block.image) ? (<div className='quiz-image-wrapper'>
      <img className="quiz-image" src={block.image.url} width={block.image.width} height={block.image.height} alt={block.name}/>
      </div>): '';
    return (
      <div>
        <Menu />
        <Title/>
        <p className="project-subtitle">{I18n.subtitle}</p>
        <div className="panel panel-info">
          <div className="quiz panel panel-info panel-body-long">
          <div className="panel-heading">
            <h4 className="panel-title">{i18n('question-prefix')} <span className="quiz-word">&laquo;{block.name}&raquo;</span></h4>
          </div>
                    {image}
            <div>
              <ul className="panel-list">
                {variants}
              </ul>
              <div className="panel-bottom clearfix">
                {controls}
                <ProgressBar
                  position={this.props.position + 1}
                  length={this.props.quiz.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

Quiz.defaultProps = {
  guideMeTo: "-1"
};

Quiz.propTypes = {
  position: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  results: PropTypes.instanceOf(Results).isRequired,
  quiz: PropTypes.array.isRequired,
  guideMeTo: PropTypes.string,
};

const ConnectedQuiz = connect(
  state => ({
    position: state.main.position,
    results: state.main.results,
    quiz: state.main.quiz,
    guideMeTo: state.main.guideMeTo,
  }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Quiz);

export default ConnectedQuiz;
