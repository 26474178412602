import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { slide as SlideMenu } from 'react-burger-menu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from '../i18n.json';
import * as actions from '../Actions';
import { linkPrefix } from '../config';
import menuStructure from '../menu';

function MenuElement(props) {
  return (
    <Link
      className={`btn ${props.className} ${(props.active) ? 'active' : ''}`}
      to={props.link || '#'}
    >{props.title}</Link>);
}

MenuElement.defaultProps = { className: '', active: false}

MenuElement.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool
};

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = { opened: false };
  }

  componentWillReceiveProps(props) {
    this.setState({ opened: false });
  }

  showSettings(event) {
    event.preventDefault();
  }

  render() {
    const { pathname } = this.props.location;
    if (this.props.embedded) return '';
    const elems = menuStructure.map(m => (<MenuElement
      key={m.path}
      active={pathname.search(m.path) >= 0}
      title={i18n[m.name]}
      link={`${linkPrefix}/${m.path}`}
      className="nav-link menu__btn"
    />));

    return (
      <SlideMenu
        isOpen={this.state.opened}
        onStateChange={state => this.setState({ opened: state.menuOpen })}
      >
        {elems}
      </SlideMenu>);
  }
}
Menu.defaultProps = { embedded: false };
Menu.propTypes = {
//  actions: PropTypes.object.isRequired,
  embedded: PropTypes.bool,
};

export function BottomMenu() {
    const elems = menuStructure.map(m => (<MenuElement
      key={m.path}
      title={i18n[m.name]}
      link={`${linkPrefix}/${m.path}`}
      className="bottomMenu__btn"
    />));
  return (<div className="footer">
      <div className="container">
        <nav>{elems}</nav>
      </div>
    </div>)
}

const ConnectedMenu = withRouter(connect(
  state => ({ embedded: state.main.embedded }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Menu));

export default ConnectedMenu;
