export const START = 'start';
export const VOTE = 'vote';
export const ERROR = 'error';
export const SENT_AUDIO = 'Audio file sent';
export const NEXT_QUESTION = 'Next question';
export const SHOW_SURVEY = 'show Survey';
export const SET_QUIZ_ID = 'set Quiz ID';
export const SET_SURVEY_DATA = 'set Survey Data';
export const SET_QUIZ_DATA = 'Set Quiz Data';
export const SET_FINISHED = 'setFinished';
export const START_QUIZ = 'startQuiz';
export const GO_PAGE = 'goPage';

export const SET_POSITION = 'back';
export const SUBMIT = 'submit';
export const SET_LOCATION = 'setLocation';
export const SET_SURVEY = 'setSurvey';
export const RECORD = 'record';
export const SET_RECORD = 'setRecord';
export const SET_SURVEY_ID = 'setSurveyId';
