/*
  global fetch
*/
import * as types from './actionTypes';
import { MAP_RESET } from './components/heatmap/actionTypes';

import {
  apiRequestRecord,
  apiPredictionQuiz,
  doNotSendAudio,
  locationsId,
  fakeLocation,
  apiPredictionQuizAnswer,
  dryRun,
} from './config';
import { sendSurvey } from './functions';
import { getCity } from './models/cities';

export function submitQuiz(cities, answers) {
  return async (dispatch, getState) => {
    const state = getState();
    const winners = cities.getWinners();
    const data = {
      answers,
      app_locale: 'es',
      area1_name: winners[0].name,
      area2_name: winners[1].name,
      area3_name: winners[2].name,
      session_id: state.main.sessionId,
    };
    try {
      if (!dryRun) {
        const response = await fetch(apiPredictionQuiz, {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data),
        });
        const result = await response.json();
        dispatch({ type: types.SET_QUIZ_ID, data: result.quiz_id });
      } else {
        console.warn(`Dry run. No actual data sent to ${apiPredictionQuiz} (${data})`);
        dispatch({ type: types.SET_QUIZ_ID, data: 666 });
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: types.ERROR, data: e });
    }
  };
}

export function nextQuestion(id) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: types.NEXT_QUESTION, data: id });
    if (state.main.position + 1 === state.main.results.length) {
      dispatch({ type: types.SHOW_SURVEY });
      const cities = state.main.results.calculateCities(state.main.quiz);
      const answers = state.main.results.prepareAnswers(state.main.quiz);
      dispatch(submitQuiz(cities, answers));
      const heatmap = cities.map((el) => {
        if (el[1]) {
          return {
            location: getCity(el[0]),
            weight: el[1] * 13,
          };
        }
        return undefined;
      });
      dispatch({ type: types.SET_QUIZ_DATA, data: { heatmap, winners: cities.getWinners() } });
    }
  };
}


export function vote(val) {
  return nextQuestion(val);
}

export function sendRecord(blob, position) {
  return async function ssR(dispatch, getState) {
    if (doNotSendAudio) {
      dispatch({ type: types.SENT_AUDIO, data: position });
      return;
    }
    try {
      const state = getState();
      const url = `${apiRequestRecord}?sessionId=${state.main.sessionId}&questionId=${state.main.position}`;
      const formData = new FormData();
      formData.append('content', blob);
      const result = await fetch(url,
        {
          method: 'POST',
          body: formData,
        });
      if (result.status === 200) {
        dispatch({ type: types.SENT_AUDIO, data: position });
      } else {
        dispatch({ type: types.ERROR, data: `Wrong response status ${result.status}` });
      }
    } catch (e) {
      dispatch({ type: types.ERROR, data: e });
    }
  };
}

function loc2name(loc) {
  return `(${loc.lat}, ${loc.lng}): ${loc.name}`;
}

export function sendSurveyAnswer(src) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      if (src.type === 'main') return;
      let answers;
      if (typeof src !== 'object') {
        answers = [{ answer: Number(src) }];
      } else {
        answers = [{ answer: locationsId[src.type], answer_text: loc2name(src) }];
      }
      const data = {
        session_id: state.main.sessionId,
        answers,
      };
      if (!dryRun) {
        const response = await fetch(apiPredictionQuizAnswer, {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data),
        });
        await response.json();
      } else {
        console.warn(`Dry run. No data saved to ${apiPredictionQuizAnswer} data=${JSON.stringify(data)}`);
      }
    } catch (e) {
      console.error('Single answer send error', e.message);
    }
  };
}


export function submitFeedback(feedback) {
  return async (dispatch, getState) => {
    const state = getState();
    const locations = state.map.locations;
    try {
      const location = locations.main;
      const lat = Math.round(location.lat * 100) / 100;
      const lng = Math.round(location.lng * 100) / 100;
      const surveyId = state.main.surveyId;
      const quizId = state.main.quizId;
      if (!quizId) {
        throw new Error('No quizId is set!');
      }
      if (!state.main.surveyData) {
        throw new Error('No surveyData is set!');
      }
      const data = {
        rating: feedback.rating,
        location: { area_lat: lat, area_lng: lng, user_lat: lat, user_lng: lng },
        comment: feedback.comment,
        session_id: state.main.sessionId,
        surveyId,
      };
      const url = `${apiPredictionQuiz}${quizId}`;
      if (!dryRun) {
        const response = await fetch(url, {
          method: 'PATCH',
          headers: new Headers({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data),
        });
        await response.json();
      } else {
        console.warn(`Dry run. No actual data sent to ${url} data: ${data}`);
      }
      dispatch({ type: types.SET_FINISHED });
    } catch (e) {
      dispatch({ type: types.ERROR, data: e.message });
      dispatch({ type: types.SET_FINISHED });
    }
  };
}

export function submitSurvey(surveyData) {
  return async (dispatch, getState) => {
    dispatch({ type: types.SET_SURVEY_DATA, data: surveyData });
    const state = getState();
    try {
      if (!state.main.quizId) {
        throw new Error('No quizId is set!');
      }
      const answers = Object.values(surveyData)
        .map(v => typeof v === 'object' ? v : { answer: v })
        .filter(v => v.answer);
      const surveyResponse = await sendSurvey(
        answers,
        fakeLocation,
        'es',
        '',
        state.main.sessionId,
        );
      dispatch({ type: types.SET_SURVEY_ID, data: surveyResponse.surveyId });
    } catch (e) {
      console.error(e);
    }
  };
}

export function back() {
  return (dispatch, getState) => dispatch({
    type: types.SET_POSITION,
    data: Math.max(0, getState().main.position - 1) });
}

export function goLast() {
  return (dispatch, getState) => dispatch({
    type: types.SET_POSITION,
    data: getState().main.results.nextPos(),
  });
}

export function restart() {
  return (dispatch) => {
    dispatch({ type: types.START });
    dispatch({ type: MAP_RESET });
  };
}

export function goPage(pageType) {
  return { type: types.GO_PAGE, data: pageType };
}

export function startQuiz() {
  return { type: types.START_QUIZ };
}

