/*
  global window
*/
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n.json';

export default function PanelQuizDone(props) {
  const winNames = [
    props.winners[0] ? props.winners[0].name : '',
    props.winners[1] ? props.winners[1].name : '',
    props.winners[2] ? props.winners[2].name : '',
  ];
  return (
    <div className="panel panel-info panel-quiz">
      <div className="panel-heading">
        <h3 className="panel-title">{i18n.thanx}</h3>
      </div>
      <div className="pnel-body">
        <div className="share-block share-title">{i18n.share}</div>
        <ul className="share-block-list">
          <li>
            <a
              className="share-block"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/home?status=Seg%C3%BAn%20la%20app%20Dialectos%20del%20espa%C3%B1ol,%20mi%20habla%20se%20acerca%20al%20dialecto%20de%0A1)%20${winNames[0]}%0A2)%20${winNames[1]}%0A3)%20${winNames[2]}%0AY%20t%C3%BA,%20%C2%BFqu%C3%A9%20dialecto%20hablas?%20https%3A//dialectosdelespanol.org%0A%23dialectos%20%23dialectosdelespanol`}
            >
              <i className="fa fa-twitter share-twitter" />
            </a>
          </li>
          <li>
            <a
              className="share-block"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A//dialectosdelespanol.org"
            >
              <i className="fa fa-facebook-official share-fb" />
            </a>
          </li>
        </ul>

        <div className="disclaimer-title">{i18n.disclaimerTitle}</div>
        {i18n.disclaimer}<br />
        <b>{props.sessionId}</b>
        <div>
          <button
            style={{ float: 'right' }}
            className="btn btn-danger btn-lg start-quiz"
            onClick={() => window.print()}
          >{i18n.printButton}</button>

          <button
            className="btn btn-success btn-lg start-quiz"
            onClick={() => props.action()}
          >{i18n.restart}</button>
        </div>
      </div>
    </div>);
}

PanelQuizDone.propTypes = {
  sessionId: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};
