/*
    global fetch
*/

import { apiSubmitSurvey, apiHeaders } from './config';

export function uniqueId() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4() + s4()}-${s4() + s4()}`;
}

export function shuffle(src) {
  let currentIndex = src.length;
  const array = src.slice();
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    const temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export async function sendSurvey(survey, location, lang, comment, session_id) {
  const lat = Math.round(location.lat * 100) / 100;
  const lng = Math.round(location.lng * 100) / 100;
  try {
    const data = {
      answers: survey,
      device_id: uniqueId(),
      location: { area_lat: lat, area_lng: lng, user_lat: lat, user_lng: lng },
      user_lng: lang,
      area_lng: lang,
      platform: 2,
      session_id,
      comment,
    };
    const response = await fetch(apiSubmitSurvey, {
      method: 'POST',
      headers: new Headers(apiHeaders),
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (e) {
    throw e;
  }
}

export function locationName(location) {
  try {
    let loc = location.find(l => l.types[0] === 'administrative_area_level_4');
    if (loc) return loc.formatted_address.split(',')[0];
    loc = location.find(l => l.types[0] === 'locality');
    if (loc) return loc.formatted_address.split(',')[0];
    loc = location.find(l => l.types[0] === 'administrative_area_level_3');
    if (loc) return loc.formatted_address.split(',')[0];
    loc = location.find(l => l.types[0] === 'administrative_area_level_2');
    if (loc) return loc.formatted_address.split(',')[0];
    loc = location.find(l => l.types[0] === 'administrative_area_level_1');
    if (loc) return loc.formatted_address;
    if (location.length >= 3) {
      return location[location.length - 2].formatted_address;
    }
    return location[location.length - 1].formatted_address;
  } catch (e) {
    console.warn(e);
    return '?';
  }
}
