import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from '../i18n.json';
import * as actions from '../Actions';
import survey from '../survey.json';
import Menu from './Menu';
import Title from './Title';


function OptionalText(props) {
  const name = `question_${props.id}`;
  const valueMain = props.data[`main_option_${props.lang}`];
  const isMain = (props.value === valueMain || !props.value);
  const valueSecond = props.data[`second_option_${props.lang}`];
  return (<div className={(props.showError && !props.value) ? `form-error` : ''}>
    <label htmlFor={name}>{props.data[`text_${props.lang}`]}</label>
    <div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name={`${name}_r1`}
          checked={isMain && props.value}
          onChange={() => props.onChange(valueMain)}
        />
        <label className="form-check-label" htmlFor={`${name}_r1`}>{valueMain}</label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name={`${name}_r2`}
          checked={!isMain}
          onChange={() => props.onChange(valueSecond)}
        />
        <label className="form-check-label" htmlFor={`${name}_r2`}>{valueSecond}</label>
      </div>
      <div className="form-group form-group-inline">
        <input
          className="form-control"
          type="text"
          onChange={e => props.onChange(e.target.value)}
          //value={props.value === valueSecond || !props.value ? '' : props.value}
          readOnly={isMain}
        />
      </div>
    </div>
  </div>);
}

OptionalText.defaultProps = { lang: 'en', value: '', showError: false };
OptionalText.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  lang: PropTypes.string,
  data: PropTypes.object.isRequired,
  showError: PropTypes.bool,
};

class Survey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitAttempt: false,
     // 0: survey[0].answers[1].id,
     // 1: survey[1].answers[2].id,
     // 2: survey[2].answers[2].id,
      3: {},// answer: survey[3].id, answer_text: survey[3].main_option_es },
      4: {},// answer: survey[4].id, answer_text: survey[4].main_option_es },
    };
  }

  submitSelect(i, evt) {
    const state = {}
    state[i] = evt.target.value;
    this.setState(state);
  }

  renderQuestion(q, i) {
    const value = this.state[i];
    const showError = this.state.submitAttempt;
    const name = `question_${i}`;
    return (<div className={`formElement ${(showError && !value) ? 'form-error' : ''}`} key={i}>
      <label htmlFor={name}>{q[`text_${this.props.lang}`]}</label>
      <select
        name={name}
        className="language form-control"
        onChange={(evt) => this.submitSelect(i, evt)}
        value={value}
        defaultValue=""
      >
        <option value="" disabled>{i18n.selectAnAnswer}</option>
        {q.answers.map(a => <option key={a.id} value={a.id}>{a[`text_${this.props.lang}`]}</option>)}
      </select>
    </div>);
  }

  render() {
    const disabled = !(this.state[0] && this.state[1] && this.state[2] && this.state[3].answer && this.state[4].answer); // Check if data is filled, if force survey.
    const questions = survey.map((q, i) => {
      switch (q.type) {
        case 0:
          return this.renderQuestion(q, i);
        case 3:
          return (<OptionalText
            showError={this.state.submitAttempt}
            lang={this.props.lang}
            key={q.id}
            id={`${q.id}`}
            data={q}
            onChange={(val) => { const ns = {}; ns[i] = { answer: q.id, answer_text: val }; this.setState(ns); }}
            value={this.state[i].answer_text}
          />);
        default:
          console.warn('Unknown question type');
          return undefined;
      }
    });
    return (
      <div>
        <Menu />
        <Title/>
        <p className="project-subtitle"></p>
        <div className="panel panel-info">

          <div className="quiz panel panel-info panel-body-long">
            <div className="panel-heading">
              <h4 className="panel-title">{i18n.surveyTitle}</h4>
            </div>
            <div className="survey-data">
              {questions}
              <div className="text-center">
                <button
                  className={`btn btn-danger btn-lg start-quiz`}
                  onClick={() => !disabled ? this.props.actions.submitSurvey({
                    ...this.state,
                    submitAttempt: undefined
                  }) : this.setState({ submitAttempt: true })}
                >{i18n.continueQuiz}</button>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

Survey.defaultProps = { lang: 'es' };
Survey.propTypes = {
  actions: PropTypes.object.isRequired,
  lang: PropTypes.string,
};

const ConnectedSurvey = connect(
  state => ({ results: state.results }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Survey);

export default ConnectedSurvey;
