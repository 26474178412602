// -----------Map control functions
import * as types from './actionTypes';
import { sendSurveyAnswer } from '../../Actions';

export function switchShowPopup() {
  return { type: types.SWITCH_SHOW_POPUP };
}

export function chooseLocation(locationType, locationTitle) {
  return { type: types.SET_LOCATION_TYPE, data: { type: locationType, title: locationTitle } };
}

// export function confirmLocation(location) {
//     return { action: types.SET_CONFIRM_LOCATION, location: location }
// }

export function setLocation(location, locType) {
  return (dispatch, getState) => {
    const state = getState();
    const locations = state.map.locations;
    const locationType = locType || state.map.locationType
    locations[locationType] = location;
    dispatch(sendSurveyAnswer({ ...location, type: locationType }));
    dispatch({ type: types.SET_LOCATION, data: locations });
  };
}

export function setMapFocus(result) {
  const zoom = (result.lng > -12) ? 6 : 3;
  return { type: types.SET_MAP_FOCUS,
    data: { center: {
      lat: Number(result.lat),
      lng: Number(result.lng),
    },
      zoom } };
}

export function expandPopup() {
  return { type: types.EXPAND_POPUP };
}