import * as types from './actionTypes';
import * as pageTypes from './pageTypes';
import { uniqueId } from './functions';
import Results from './models/results';
import { shuffle } from './functions';

const quiz = shuffle(Object.values(require('./quiz')));

// const winnersMock = [
//    {  "name": "Andalucía", "map": "andalusia", "lng": -4.575609, "lat": 37.46335 },
//    {  "name": "Aragón ", "map":  "aragon", "lng": -0.659945, "lat": 41.519528 },
//    {  name: 'America',     "lng": "-64.168459", "lat": "-33.528049", "map": "america"  },
// ];

// const genderMock = { gender: 1, aaa: 2}


// const initialState = {
//   position: 0,
//   sessionId: uniqueId(),
//   quizId: 666,
//   surveyData: genderMock,
//   page: pageTypes.PAGE_MAP,
//   results: new Results(quiz.length),
//   winners: winnersMock,
//   finished: false,
//   quiz,
// };

const embedded = window.location.search.indexOf('embed') !== -1;
const urlParams = new URLSearchParams(window.location.search);
const guideMeTo = String(urlParams.get('guideMeTo'));

const initialState = {
  position: 0,
  sessionId: uniqueId(),
  quizId: 666,
  surveyData: undefined,
  page: pageTypes.PAGE_QUESTION,
  quiz,
  results: new Results(quiz.length),
  winners: [],
  heatmap: [],
  finished: false,
  embedded,
  guideMeTo,
};

function setVote(state, data) {
  const results = state.results;
  results.set(data, state.position);
  return { ...state, results, page: pageTypes.PAGE_RECORD };
}

function setRecorded(state, data) {
  const results = state.results;
  results.setRecorded(data);
  return { ...state, results };
}

export default function reducer(state = initialState, action) {
 //  console.log('Reducer', action, state);
  const data = action.data;
  switch (action.type) {
    case types.ERROR:
      console.error(`Handled error: ${data}`);
      return { ...state, error: data };
    case types.NEXT_QUESTION:
      const results = state.results;
      results.set(data, state.position);
      return { ...state, position: state.position + 1, results, page: pageTypes.PAGE_QUESTION };
    case types.SHOW_SURVEY:
      return { ...state, page: pageTypes.PAGE_SURVEY };
    case types.VOTE:
      return setVote(state, data);
    case types.SET_SURVEY_DATA:
      return { ...state, surveyData: data, page: pageTypes.PAGE_MAP };
    case types.SET_QUIZ_ID:
      return { ...state, quizId: data };
    case types.SET_QUIZ_DATA:
      return { ...state, ...data };
    case types.SET_POSITION:
      return { ...state, position: data, page: pageTypes.PAGE_QUESTION };
    case types.SENT_AUDIO:
      return setRecorded(state, data);
    case types.SET_FINISHED:
      return { ...state, finished: true }
    case types.START:
      return { ...initialState, page: pageTypes.PAGE_QUESTION, results: new Results(quiz.length), sessionId: uniqueId() }
    case types.GO_PAGE:
      return { ...state, page: data }
    case types.START_QUIZ:
      return { ...initialState, page: pageTypes.PAGE_QUESTION }
    default:
      return state;
  }
}
