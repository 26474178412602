import * as types from './actionTypes';

const initialState = {
  popupExpanded: true,
  locationType: '',
  locations: {},
  mapData: {},
};

export default function reducer(state = initialState, action) {
  // console.log('Map Reducer', action, state);
  const data = action.data;
  switch (action.type) {
    case types.SWITCH_SHOW_POPUP:
      return { ...state, popupExpanded: !state.popupExpanded, locationType: '' };
    case types.SET_LOCATION_TYPE:
      return { ...state, popupExpanded: false, locationType: data.type, locationTitle: data.title };
    case types.SET_LOCATION:
      return { ...state, locations: data };
    case types.EXPAND_POPUP:
      return { ...state, popupExpanded: true };
    case types.SET_MAP_FOCUS:
      return { ...state, mapData: data };
    case types.MAP_RESET:
      return { popupExpanded: true, locationType: '', locations: {}, mapData: {}}
    default:
      return state;
  }
}
