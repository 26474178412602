import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../Actions';
import * as pageTypes from '../pageTypes';
import { linkPrefix } from '../config';
import Menu from './Menu';
import i18n from '../i18n.json';
import Title from './Title';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Intro(props) {
  const { pathname } = props.location;
  const page = (pathname.length > linkPrefix.length + 2) ? pathname.replace(`${linkPrefix}/`, '') : pageTypes.PAGE_DEFAULT;
  const title = i18n[`page${capitalizeFirstLetter(page)}-title`];
  const text = i18n[`page${capitalizeFirstLetter(page)}-text`];
  const subtext = i18n[`page${capitalizeFirstLetter(page)}-subtext`] || '';
  if (props.embedded) {
    return <Redirect to={`${linkPrefix}/quiz`} />;
  }

  return (
    <div>
      <div className="title">
        <Menu burgerButtonClassName={ "menu--burger-button" }/>
        <Title />
      </div>
      <div className="panel panel-info">
        <div className="quiz panel panel-info panel-body-long">
          <div className="staticPage__body">
            <h3 className="staticPage__title">{title}</h3>
            <div className="staticPage__content" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <div className="text-center">
            <Link
              className="btn btn-success btn-lg start-quiz"
              to={`${linkPrefix}/quiz`}
            >{i18n.startQuiz}</Link>
          </div>
          <div className="staticPage__content" style={{ paddingTop: '8pt' }} dangerouslySetInnerHTML={{ __html: subtext }} />
          {props.children}
        </div>
      </div>
    </div>);
}

Intro.propTypes = {
  location: PropTypes.object.isRequired,
  embedded: PropTypes.bool,
};


const ConnectedIntro = withRouter(connect(
  (state) => ({ embedded: state.main.embedded }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
)(Intro));

export default ConnectedIntro;
