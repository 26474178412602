/*
  global google
*/
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import ReactMapGL, { Marker, Popup, NavigationControl } from 'react-map-gl';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  MapboxKey,
  defaultZoom,
  linkPrefix,
  WinnerColors,
  singleMarker,
  hideMarkersOnResults,
  useGoogle,
} from '../config';
import * as actions from './heatmap/actions';
import { submitFeedback, startQuiz, restart } from '../Actions';
import i18n from '../i18n.json';
import LocationSurvey from './LocationSurvey';
import PanelQuizDone from './PanelQuizDone';
import { locationName } from '../functions';
import Title from './Title';
import { defaultMapStyle } from './map/map-style.js';
import Pin from './map/pin';



// const googleMapURL = `https://maps.googleapis.com/maps/api/js?libraries=visualization&key=${GoogleKey}`;

// const data2marker = function data2market(el, type) {
//   const marker = {
//     type,
//     lat: parseFloat(el.area_lat),
//     lng: parseFloat(el.area_lng),
//   };
//   Object.assign(marker, el);
//   return marker;
// };

function googleRG(latLng, title) {
  const gk = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    gk.geocode({ location: latLng }, (res) => {
      resolve({
        name: locationName(res),
        lat: latLng.lat,
        lng: latLng.lng,
        title,
      });
    });
  });
}

async function locationiqRG(latLng, title) {
  const url = `https://eu1.locationiq.com/v1/reverse.php?key=cf717c87748b5d&lat=${latLng.lat}&lon=${latLng.lng}&format=json&normalizecity=1`;
  let city = '?';
  try {
    const res = await fetch(url);
    const data = await res.json();
    city = data.address.city;
  } catch (e) {
    console.warn(e);
  }
  return {
    name: city,
    lat: latLng.lat,
    lng: latLng.lng,
    title,
  };
}


const renderMarker = function renderMarker(marker, onClick) {
  const latitude = marker.lat;
  const longitude = marker.lng;
  return (<Marker
    key={marker.key || (marker.area_name_en + marker.type + marker.lat)}
    latitude={latitude}
    offsetTop={-20}
    offsetLeft={-10}
    longitude={longitude}
    onClick={() => onClick(marker)}
    icon={marker.icon}
  >
    <Pin size={20} />

  </Marker>);
};

const renderPopup = function renderPopup(marker, onClose, showPopup) {
 // const info = (marker.text) ? <InfoWindow><div>{marker.text}</div></InfoWindow> : undefined;
  if (!marker.text || !showPopup) return undefined;
  const latitude = marker.lat;
  const longitude = marker.lng;
   const info =  <div className='map--popup'>{marker.text}</div>;
  return (<Popup
    key={`popup${marker.key || (marker.area_name_en + marker.type + marker.lat)}`}
    latitude={latitude}
    offsetTop={-20}
    offsetLeft={-10}
    longitude={longitude}
    onClose={() => onClose(marker)}
  >
    {info}
  </Popup>);
};

class MapComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mapStyle: defaultMapStyle,
      showPopup: true,
      viewport: {
        latitude: 0.7577,
        longitude: -12.4376,
        zoom: 2,
        bearing: 0,
        pitch: 0,
      },
    };
  }

  componentDidMount() {
    this.props.winners.forEach((winner, i) => {
      try {
        this.loadData(`${linkPrefix}/json/${winner.map}.geojson`, winner.map, i);
      } catch (e) {
        console.log(`error 4 ${winner.name}`, e);
      }
    });
  }

  loadData(data, name, place) {
    const mapStyle = { ...this.state.mapStyle };

    mapStyle.sources[name] = { type: 'geojson', data };
    mapStyle.layers.push({
      id: name,
      source: name,
      type: 'fill',
      interactive: false,
      paint: {
        'fill-color': WinnerColors[place],
        'fill-opacity': 0.4,
      },
    });
    this.setState({ mapStyle });
  }

  _getCursor = ({ isHovering, isDragging }) => this.props.setLocation ? 'pointer' : 'default';

  _onViewportChange = (viewport) => this.setState({ viewport });

  render() {
    const props = this.props;
    return (
      <ReactMapGL
        width="100%"
        height="90%"
        mapStyle={this.state.mapStyle}
        {...this.state.viewport}
        getCursor={this._getCursor}
        mapboxApiAccessToken={MapboxKey}
        onViewportChange={this._onViewportChange}
        onClick={d => props.onMapClick(d)}
      >
        {props.markers.map(marker => renderMarker(marker, props.onMarkerClick))}
        {props.markers.map(marker => renderPopup(marker, () => this.setState({ showPopup: false }), this.state.showPopup ))}
        <div className="nav" >
          <NavigationControl />
        </div>
      </ReactMapGL>

    );

  //     ref={props.onMapWillMount}
  // defaultZoom={defaultZoom}
  // defaultCenter={props.center || Center}
  // center={props.center}
  // zoom={props.zoom}
  //     // Pass the map reference here as props
  // // googleMapURL={googleMapURL}
  // options={createMapOptions()}
  //
  }
}


class MyKmlMap extends Component {

  constructor(props) {
    super(props);
    this.state = { center: undefined, showPanel: false };
  }

  componentDidMount() {
    if (!this.props.locations.main) {
      this.props.actions.setLocation(this.props.winners[0], 'main');
      this.forceUpdate();
    }
    setTimeout(() => this.setState({ showPanel: true }), 3000);
  }
  onMapLoad(map) {
    this.map = map;
  }

  async onMapClick(param) {
    const mprm = {
      lat: param.lngLat[1],
      lng: param.lngLat[0],
    };
    if (!this.props.locationType || this.props.popupExpanded) return;
    console.log(param);
    const loc = await ((useGoogle) ? googleRG(mprm, this.props.locationTitle) : locationiqRG(mprm, this.props.locationTitle));
    this.props.actions.setLocation(loc);
    this.forceUpdate();
  }

  onMarkerClick(marker) {
  //   if (marker.area_hash) {
    this.setState({ center: marker });
  //       area: marker,
  //       compact: { quiz: false, city: true },
  //       pictureGame: [],
  //       freeSpeech: [],
  //     });
  //     this.fetchPictureGame(marker);
  //     this.fetchFreeSpeech(marker);
  //   }
  }

  quizResults() {
    const markers = [];
    const heatmap = this.props.heatmap;
    const wins = this.props.winners || [];
    this.storedMarkers = []; // this.state.markers;
    wins.forEach((cnt, i) => {
      markers.push({
        lat: Number(cnt.lat),
        lng: Number(cnt.lng),
        area_name_en: i,
        icon: `/img/mark-${i + 1}.png`,
        type: i,
        text: (i === 0) ? `${i18n['map-quiz-target']} ${cnt.name}` : false,
      });
    });
    return {
      center: this.props.mapData.center || wins[0],
      markers,
      heatmap,
      location: wins[0],
    };
  }

  chooseLocations() {
    const locType = this.props.locationType;
    let locations = this.props.locations;
    if (singleMarker) {
      locations = this.props.locations[locType] ? [this.props.locations[locType]] : [];
    }
    if (hideMarkersOnResults && this.props.popupExpanded) {
      locations = [];
    }
    const markers = Object.values(locations).map(loc => ({
      key: `${loc.name}${loc.lat}${loc.lng}`,
      lat: loc.lat,
      lng: loc.lng,
      text: loc.title,
      icon: '/img/mark-home.png' }));
    return { heatmap: undefined, winners: undefined, markers };
  }

  i18n(key) {
    return i18n[key] || `Not found in i18n file: ${key}`;
  }

  expandQuiz() {
    const markers = this.storedMarkers || this.state.markers;
    this.setState({ compact: { quiz: true, city: false }, markers, heatmap: [] });
  }

  renderArea() {
      // Pretty height
    let height = window.innerHeight;
    if (height >= 768) {
      height -= 520;
    } else {
      height -= 260;
    }
    const style = { maxHeight: `${height}px` };

    return (<div className="data-area panel">
      <div className="panel-heading">
        <h3 className="panel-title">
          <i
            role="button"
            tabIndex={0}
            className="glyphicon glyphicon-remove panel-close"
            onClick={() => this.setState({ compact: { quiz: true, citycd: false } })}
          />
        </h3>
      </div>
      <div
        className="panel-body"
        style={style}
      />
    </div>
    );
  }

  render() {
    const data = (this.props.locationType) ? this.chooseLocations() : this.quizResults();
    const area = this.renderArea();
    let panel = (!this.props.finished) ? (<LocationSurvey
      onSubmit={d => this.props.actions.submitFeedback(d)}
      location={this.props.locations.main}
      winners={this.props.winners}
    />) : (<PanelQuizDone
      sessionId={this.props.sessionId}
      action={() => this.props.actions.restart()}
      winners={this.props.winners}
    />);
    if (!this.state.showPanel) panel = '';
    return (
      <div className="map--page">
        <div className="popup-wr">
          <Title />
          <div className="col-xs-4 menu">
            {area}
            {panel}
          </div>

          <MapComponent
            setLocation={this.props.locationType}
            markers={data.markers}
            center={data.center}
            winners={this.props.winners}
            loadingElement={<div style={{ height: '10%' }} />}
            zoom={this.props.mapData.zoom || defaultZoom}
            onMapLoad={m => this.onMapLoad(m)}
            onMarkerClick={marker => this.onMarkerClick(marker)}
            containerElement={<div className="mapContainer" />}
            onMapClick={d => this.onMapClick(d)}
            mapElement={<div className="mapElement" />}
          />
        </div>
      </div>);
  }
}

MyKmlMap.defaultProps = { locationType: '', heatmap: [], popupExpanded: true };

MyKmlMap.propTypes = {
  winners: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  finished: PropTypes.bool.isRequired,
  sessionId: PropTypes.string.isRequired,
  locations: PropTypes.object.isRequired,
  locationType: PropTypes.string,
  locationTitle: PropTypes.string,
  mapData: PropTypes.object.isRequired,
  heatmap: PropTypes.array,
  popupExpanded: PropTypes.bool,
};
const ConnectedKmlMap = connect(
  state => ({
    winners: state.main.winners,
    finished: state.main.finished,
    sessionId: state.main.sessionId,
    locationType: state.map.locationType,
    locations: state.map.locations,
    locationTitle: state.map.locationTitle,
    mapData: state.map.mapData,
    popupExpanded: state.map.popupExpanded,
  }),
  dispatch => ({ actions: bindActionCreators({
    ...actions,
    submitFeedback,
    startQuiz,
    restart,
  }, dispatch) }),
)(MyKmlMap);

export default ConnectedKmlMap;
