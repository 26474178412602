import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  isMobile,
} from 'react-device-detect';
import i18n from '../i18n.json';

import * as actions from './heatmap/actions';
import { sendSurveyAnswer } from '../Actions';

function LocationButton(props) {
  const isActive = (props.value && props.value.name);
  const title = (isActive) ? props.value.name : props.placeholder;
  const useRow = props.title.length < 30;
  return (<div
    errable="true"
    className={`your-city survey-location-wrapper ${useRow ? 'row' : ''} ${props.showError && !isActive ? 'form-error' : ''}`}
  >
    <div className={`${useRow ? 'col-md-4' : ''} your-city-ttl`}>
      {props.title}
    </div>
    <div className={useRow ? 'col-md-8' : ''}>
      <button
        className={`btn survey-location ${isActive ? 'btn-success' : 'btn-warning'} `}
        onClick={() => props.action(props.name, props.title)}
      >
        {title}
      </button>
    </div>
  </div>);
}

LocationButton.defaultProps = {
  value: undefined,
  showError: false,
  placeholder: i18n.clickToSetLocation,
};
LocationButton.propTypes = {
  value: PropTypes.object,
  showError: PropTypes.bool,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
class PostSurvey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      submitAttempt: false,
      comment: '',
      moved: i18n.timesMoved.options[0].value,
      movedcountry: i18n.timesMoved2.options[0].value,
      commentFocused: false,
    };
  }

  get title() {
    return (!this.props.popupExpanded && this.props.locationTitle) ?
      this.props.locationTitle :
      i18n.locationPopupTitle;
  }

  get commentBlock() {
    return (<div className="your-city">
      <textarea
        placeholder={i18n['quiz-comment']}
        value={this.state.comment}
        rows={3}
        onFocus={() => this.setState({ commentFocused: true })}
        onBlur={() => this.setState({ commentFocused: false })}
        onChange={e => this.setState({ comment: e.target.value })}
        className="form-control quiz-comment"
        type="text"
      />
    </div>);
  }

  get okButton() {
    const locs = this.props.locations;
    const enabled = this.state.rating && locs.liveIn && locs.born && locs.grown;
    return (<div>
      <button
        className={'btn btn-danger btn-lg start-quiz'}
        onClick={() => enabled ? this.props.onSubmit(this.state) : this.setState({ submitAttempt: true })}
      >{i18n['quiz-submit-post-survey']}</button>
    </div>);
  }

  renderBody() {
    if (this.state.commentFocused && isMobile) {
      return (
        <div className="location-survey">
          {this.commentBlock}
          {this.okButton}
        </div>);
    }
    const winNames = [
      this.props.winners[0] ? this.props.winners[0].name : '',
      this.props.winners[1] ? this.props.winners[1].name : '',
      this.props.winners[2] ? this.props.winners[2].name : '',
    ];
    return (
      <div className="location-survey">
        <div className="panel-heading">
          <h4 className="panel-title">{i18n.locationSurveyTitle}
            <i
              role="button"
              className="fa fa-window-close popup-head-button"
              onClick={this.props.actions.switchShowPopup}
            />
          </h4>
        </div>
        <div className="quiz-winner">
          <div className="quiz-winner-title">{i18n.quizRestOfCites}</div>
          <ol>
            <li><button
              className="btn surveyLocation--result-button"
              onClick={() => this.props.actions.setMapFocus(this.props.winners[0])}
            >
              <span className="glyphicon glyphicon-ok" aria-hidden="true" />
              <span className="surveyLocation--result-first">{winNames[0]}</span>
            </button>
            </li>
            <li><button
              className="btn surveyLocation--result-button"
              onClick={() => this.props.actions.setMapFocus(this.props.winners[1])}
            >
              <span className="glyphicon glyphicon-ok" aria-hidden="true" />
              <span className="surveyLocation--result-second">{winNames[1]}</span>
            </button>
            </li>
            <li><button
              className="btn surveyLocation--result-button"
              onClick={() => this.props.actions.setMapFocus(this.props.winners[2])}
            >
              <span className="glyphicon glyphicon-ok" aria-hidden="true" />
              <span className="surveyLocation--result-third">{winNames[2]}</span>
            </button>

            </li>
          </ol>

          <div className="location-survey-message" dangerouslySetInnerHTML={{ __html: i18n.helpUsMessage }} />
          <hr className="location-survey-hr" />
          <div className="location-survey-locButtons">
            <LocationButton
              showError={this.state.submitAttempt}
              title={i18n.chooseBornLocationMap}
              value={this.props.locations.born}
              name="born"
              action={this.props.actions.chooseLocation}
            />
            <LocationButton
              showError={this.state.submitAttempt}
              title={i18n.chooseGrownLocationMap}
              value={this.props.locations.grown}
              name="grown"
              action={this.props.actions.chooseLocation}
            />
            <LocationButton
              showError={this.state.submitAttempt}
              title={i18n.chooseLiveInLocationMap}
              value={this.props.locations.liveIn}
              name="liveIn"
              action={this.props.actions.chooseLocation}
            />
          </div>
          <div className="formElement your-city">
            <label htmlFor="timesMoved">{i18n.timesMoved.label}</label>
            <select
              name="timesMoved"
              className="language form-control"
              onChange={(e) => {
                this.setState({ moved: e.target.value });
                this.props.actions.sendSurveyAnswer(e.target.value);
              }}
              value={this.state.moved}
            >
              {i18n.timesMoved.options.map(a => (<option key={a.value} value={a.value}>
                {a.name}
              </option>))}
            </select>
          </div>

          <div className="formElement your-city">
            <label htmlFor="timesMovedCountry">{i18n.timesMoved2.label}</label>
            <select
              name="timesMovedCountry"
              className="language form-control"
              onChange={(e) => {
                this.setState({ movedcountry: e.target.value });
                this.props.actions.sendSurveyAnswer(e.target.value);
              }}
              value={this.state.movedcountry}
            >
              {i18n.timesMoved2.options.map(a => (<option key={a.value} value={a.value}>
                {a.name}
              </option>))}
            </select>
          </div>


          <div className={`stars ${this.state.submitAttempt && !this.state.rating ? 'form-error' : ''} `}>
            <div dangerouslySetInnerHTML={{ __html: i18n['quiz-stars-title'] }} />
            <ReactStars
              className="stars-wr"
              count={5}
              value={this.state.rating}
              onChange={r => this.setState({ rating: r })}
              size={35}
              color2={'#ffd700'}
            />
          </div>
          {this.commentBlock}
          {this.okButton}
        </div>
      </div>
    );
  }

  renderLocationBlock() {
    const buttonClass = this.props.popupExpanded ? 'window-close' : 'window-maximize';
    if (!this.props.locationType) {
      return (<div className="panel-heading">
        <h4
          className="panel-title"
          style={{ cursor: 'pointer' }}
          onClick={this.props.actions.switchShowPopup}
        >{i18n.pressToSetLocation}<i className={`fa fa-${buttonClass} popup-title-button`} />
        </h4>
      </div>);
    }
    const currentLoc = this.props.locations[this.props.locationType];
    const enabled = typeof currentLoc === 'object';
    return (
      <div>
        <div className="panel-heading">
          <h4
            className="panel-title"
            style={{ cursor: 'pointer' }}
            onClick={this.props.actions.switchShowPopup}
          >{this.title}<i className={`fa fa-${buttonClass} popup-title-button`} />
          </h4>
        </div>
        <LocationButton
          title={i18n.clickOnMap}
          value={currentLoc}
          name="liveIn"
          action={() => {}}
          placeholder={i18n.clickToSetLocationNone}
        />
        <div className="surveyLocation--expand">
          <button
            className={`btn btn-success btn-lg start-quiz ${(!enabled) ? 'disabled' : ''}`}
            onClick={() => enabled && this.props.actions.expandPopup(this.state)}
          >{i18n.buttonExpandLocationSurvey}</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="panel panel-info panel-quiz">

        {this.props.popupExpanded ? this.renderBody() : this.renderLocationBlock() }
      </div>);
  }
}
PostSurvey.defaultProps = { locationTitle: '', popupExpanded: true, locationType: '' };
PostSurvey.propTypes = {
  locationTitle: PropTypes.string,
  locations: PropTypes.object.isRequired,
  winners: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  popupExpanded: PropTypes.bool,
  locationType: PropTypes.string,
};

const ConnectedPostSurvey = connect(
  state => ({
    popupExpanded: state.map.popupExpanded,
    locationType: state.map.locationType,
    locations: state.map.locations,
    locationTitle: state.map.locationTitle,
  }),
  dispatch => ({ actions: bindActionCreators({ ...actions, sendSurveyAnswer }, dispatch) }),
)(PostSurvey);

export default ConnectedPostSurvey;
