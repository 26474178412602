import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Helmet } from 'react-helmet';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Route, Switch, Redirect } from 'react-router';
import {
  routerReducer,
  routerMiddleware,
} from 'react-router-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import QuizWithRecord from './components/QuizWithRecord';
import reducer from './reducer';
import i18n from './i18n.json';
import Intro from './components/Intro';
import { linkPrefix } from './config';
import mapReducer from './components/heatmap/reducer';
import menuStructure from './menu';
import { BottomMenu } from './components/Menu';

const createBrowserHistory = require("history").createBrowserHistory;
require('typeface-dosis');
require('./App.css');

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const store = createStore(
  combineReducers({ main: reducer, router: routerReducer, map: mapReducer }),
  compose(applyMiddleware(thunk), applyMiddleware(middleware)),
);


export default function App() {
  const menuRoutes = menuStructure.map(m => <Route path={`${linkPrefix}/${m.path}`} component={Intro} key={m.path} />);
  return (
    <div className="App">
      <Helmet>
        <title>{i18n.htmlTitle}</title>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
          integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4"
          crossOrigin="anonymous"
        />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          crossOrigin="anonymous"
        />

      </Helmet>
      <Provider store={store}>
          <Router>
            <div className='quiz-content'>
              <Switch>
                <Route exact path={`${linkPrefix}/`} component={() => <Redirect to={`${linkPrefix}/${menuStructure[0].path}`} />} />
                {menuRoutes}
                <Route path={`${linkPrefix}/quiz`} component={QuizWithRecord} />
                <Route path="" component={Intro} />
              </Switch>
            </div>
            <BottomMenu/>
          </Router>
      </Provider>
    </div>
  );
}


//
  //           <Route path="/survet" component={Survey} />
  //           <Route path="/record" component={SoundRecord} />
  //           <Route path="/heatmap" component={HeatMap} />
