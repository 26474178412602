import CitiesData from '../cities.json';

Object.keys(CitiesData).forEach(cityId => {
    CitiesData[cityId].lat = Number(CitiesData[cityId].lat)
    CitiesData[cityId].lng = Number(CitiesData[cityId].lng)
})

export function getCity(id) {
    if (CitiesData[id]) {
        return CitiesData[id];
    } else {
        console.warn(`Wrong city ID: '${id}'.`);
    }
}

export default class Cities extends Array {

  constructor(data) {
    super();
    if (data && data.length) {
      data.forEach(e => this.push(e));
    }
  }

  get(i) {
    try {
        return CitiesData[this[i][0]];
    } catch (e) {
        console.warn(e);
    }
  }

  getName(i) {
    const obj = this.get(i);
    return (obj) ? obj.name : '';
  }

  getWinners() {
    const winners = [this.get(0), this.get(1), this.get(2)];
    let sidx = -1;
    let oidx = -1
    winners.forEach((e, i) => {
      let tidx = winners.findIndex(e1 => (e.id === e1.parent) );
      if (tidx > i) { oidx = i; sidx = tidx; }
    })
    if (sidx >=0 && oidx >=0 ) {
      winners.splice(sidx + 1, 0, winners[oidx]);
      winners.splice(oidx, 1);
    }
    return winners;
  }


}

