import React from 'react';
import i18n from '../i18n.json';

export default function Title() {
    return (<h1 className="project-title navbar-light">
        <a href='/'>
        <img src='/android-icon-48x48.png' className='project-title--icon' alt=''/>{i18n.title}
        </a>
    </h1>)
}

